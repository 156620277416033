<template>
  <div>
    <el-dialog
      title=""
      :visible.sync="dialogVisible"
      width="75%"
      :before-close="handleClose"
    >
      <div class="img-dialog">
        <!--  -->
        <el-form
          label-position="right"
          label-width="150px"
          :rules="rules"
          :model="formData"
          :inline="true"
          ref="dataForm"
        >
          <el-form-item label="应用代码" prop="appCode">
            <el-input
              v-model="formData.appCode"
              :disabled="!!this.watchItem.id"
              style="width: 194px"
            ></el-input>
          </el-form-item>
          <el-form-item label="请输入应用描述" prop="appDesc">
            <el-input
              v-model="formData.appDesc"
              style="width: 194px"
            ></el-input>
          </el-form-item>
          <el-form-item label="应用key" prop="appKey">
            <el-input
              v-model="formData.appKey"
              style="width: 194px"
              disabled
            ></el-input>
          </el-form-item>
          <el-form-item label="应用回调地址" prop="notifyUrl">
            <el-input
              v-model="formData.notifyUrl"
              style="width: 194px"
            ></el-input>
          </el-form-item>
          <el-form-item label="是否展示首页" prop="indexFlag">
            <el-select v-model="formData.indexFlag" placeholder="是否展示首页">
              <el-option
                v-for="item in indexFlagOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
              <!-- <el-option label="展示" value="Y"> </el-option>
                            <el-option label="不展示" value="N"> </el-option> -->
            </el-select>
          </el-form-item>
          <el-form-item label="主题色" prop="subjectColor">
            <el-input
              v-model="formData.subjectColor"
              style="width: 194px"
            ></el-input>
          </el-form-item>
          <el-form-item label="辅色" prop="secondaryColor">
            <el-input
              v-model="formData.secondaryColor"
              style="width: 194px"
            ></el-input>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose">取 消</el-button>
        <el-button type="primary" @click="isHandelSure">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import cloneDeep from 'lodash/cloneDeep';
// import editorOption from './editorOption';
const URL_OBJ = {
  save: '/sysapp/save',
  update: '/sysapp/update',
};

export default {
  data() {
    return {
      limitList: [],
      rules: {
        appCode: [
          {
            required: true,
            message: '请输入应用代码',
            trigger: 'blur',
          },
        ],
        appDesc: [
          {
            required: true,
            message: '请输入应用描述',
            trigger: 'blur',
          },
        ],
      },
      dialogVisible: false,
      formData: {
        appCode: '', // 应用code
        appDesc: '', // 请输入应用描述
        notifyUrl: '', // 请输入应用回调地址
        appKey: '',
        indexFlag: '', // 默认不展示
        subjectColor: '', // 主题色
        secondaryColor: '', // 辅色
      },
      indexFlagOptions: [
        {
          value: 'Y',
          label: '展示',
        },
        {
          value: 'N',
          label: '不展示',
        },
      ],
    };
  },
  props: {
    itemData: {
      type: Object,
      require: false,
    },
  },
  watch: {
    watchItem: {
      immediate: true,
      deep: true,
      handler(newVal) {
        let deepObj = {};
        if (Object.keys(newVal).length) {
          deepObj = cloneDeep(newVal);
        }
        this.formData = deepObj;
        if (!this.watchItem.id) this.formData.indexFlag = 'N';
      },
    },
  },
  mounted() {},
  methods: {
    isHandelSure() {
      this.$confirm('确认提交？')
        .then(async (_) => {
          // done();
          this.handelSure();
        })
        .catch((_) => {});
    },
    handelSure() {
      this.$refs['dataForm'].validate(async (valid) => {
        if (!valid) return;

        //编辑
        if (this.watchItem.id) {
          //
          const res = await this.$http.post(URL_OBJ.update, this.formData);
          if (res.data.code != 0) {
            this.$message.error(res.data.msg);
            return;
          }
          this.$emit('updatTable');
        } else {
          //新增

          const res = await this.$http.post(URL_OBJ.save, this.formData);
          if (res.data.code != 0) {
            this.$message.error(res.data.msg);
            return;
          }
          this.$emit('updatTable');
        }
        this.dialogVisible = false;
      });
    },
    handleClose() {
      this.$confirm('确认关闭？')
        .then(async (_) => {
          // done();
          this.dialogVisible = false;
          this.$refs['formData'].resetFields();
          // Object.assign(this.$data, this.$options.data());
        })
        .catch((_) => {});
    },
    bannerSuccess(res) {
      this.$set(this.formData, 'productPicture', this.initImg(res));

      // this.formData.productPicture = URL.createObjectURL(file.raw);
    },
    listSuccess(res) {
      this.$set(this.formData, 'productListPicture', this.initImg(res));
    },
    shareSuccess(res) {
      this.$set(this.formData, 'sharePicture', this.initImg(res));
    },

    initImg(res) {
      const { code = 99, data = {}, msg = '网络异常' } = res;
      if (code != 0) {
        this.$message.error(msg);
        return false;
      }
      return data.src;
    },
    onEditorChange({ _, html, text }) {
      console.log(html, text);
      this.formData.productDetails = html;
    },
  },
  computed: {
    watchItem() {
      return this.itemData;
    },
  },
};
</script>
